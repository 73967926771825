.dropzone {
  background-color: #fafafa;
  border: 3px dashed #8a8a8a;
  color: #8a8a8a;
  padding: 5px;
  text-align: center;
}
.dropzone-accept {
  border-color: #a0c46d !important;
}

.dropzone-reject {
  border-color: #b71d1a !important;
}
