.cqrnext {
  background: #e6e7e8;
}
.cqrnext:hover {
  color: #32355C;
}
.cqrback {
  background: #e6e7e8;
}
.cqrback:hover {
  color: #32355C;
}
